// components/Forum.js
import React from 'react';
import styled from 'styled-components';

const ForumContainer = styled.div`
  padding: 20px;
  background: #1a1a1a;
  border-radius: 8px;
  color: #e0e0e0;
  font-family: 'Orbitron', sans-serif; /* Application de la police Orbitron */
`;

const ForumTitle = styled.h2`
  color: #ff007f;
  font-family: 'Orbitron', sans-serif; /* Application de la police Orbitron */
`;
// Composant pour le texte violet
const PurpleText = styled.span`
  color: violet;
`;
const Doc = () => {
  return (
    <ForumContainer>
      <ForumTitle>Forum</ForumTitle>
      <p>
        Depuis toujours, j’ai été attiré par les musiques rapides, électro dynamiques et la synthwave. Ce gout m’a entrainé à créer ce site pour partager avec vous ces genres musicaux qui me fascinent tant.
      </p>
      <p>
        Je souhaite partager mes découvertes musicales, même si mes goûts sont assez aléatoire. Mon site est <PurpleText> pour ceux qui apprécient la synthwave,pop et l’électro.</PurpleText>
      </p>
      <p>
        Mon objectif principal est de <PurpleText>constituer un grand répertoire de musique.</PurpleText> Je veux montrer aux visiteurs des musiques pour qu’ils puissent <PurpleText>explorer et apprécier ce genre.</PurpleText>
      </p>
      <p>
        Pour l’instant, le site se concentre <PurpleText>exclusivement sur la musique.</PurpleText> 
      </p>
      <p>
        Le site propose principalement de la <PurpleText>musique synthwave, pop et electro.</PurpleText> Vous y trouverez une variété de morceaux pour satisfaire vos oreilles.
      </p>
      <p>Je montre mon site sur Instagram.</p>
      <p>
        J'encourage les visiteurs à me faire part de leurs retours en m’envoyant des messages privée sur Instagram.( je ne mange pas (pas encore))
      </p>
      {/* Ajoute ici les éléments de ton forum, comme les discussions, les commentaires, etc. */}
    </ForumContainer>
  );
};

export default Doc;
