import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import io from 'socket.io-client';

const ChatContainer = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.3);
`;

const ChatMessages = styled.div`
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
`;

const MessageBubble = styled.div`
  background: ${props => props.isCurrentUser ? 'rgba(255, 0, 255, 0.3)' : 'rgba(0, 255, 255, 0.3)'};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  align-self: ${props => props.isCurrentUser ? 'flex-end' : 'flex-start'};
`;

const UserName = styled.span`
  font-weight: bold;
  color: #00ffd5;
  margin-right: 10px;
`;

const MessageText = styled.span`
  color: #ffffff;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const Button = styled.button`
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(255, 0, 255, 0.7);
  }
`;

const Chatroom = () => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [isUserNameSet, setIsUserNameSet] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const newSocket = io('http://localhost:4000');
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('init messages', (initMessages) => {
        setMessages(initMessages);
      });

      socket.on('chat message', (msg) => {
        setMessages((prevMessages) => [...prevMessages, msg]);
      });
    }
  }, [socket]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '' && socket) {
      const messageData = { user: userName, text: inputMessage };
      socket.emit('chat message', messageData);
      setInputMessage('');
    }
  };

  const handleSetUserName = () => {
    if (userName.trim() !== '') {
      setIsUserNameSet(true);
    }
  };

  return (
    <ChatContainer>
      {!isUserNameSet ? (
        <>
          <Input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your username"
          />
          <Button onClick={handleSetUserName}>Set Username</Button>
        </>
      ) : (
        <>
          <ChatMessages>
            {messages.map((msg, index) => (
              <MessageBubble key={index} isCurrentUser={msg.user === userName}>
                <UserName>{msg.user}:</UserName>
                <MessageText>{msg.text}</MessageText>
              </MessageBubble>
            ))}
            <div ref={messagesEndRef} />
          </ChatMessages>
          <Input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type your message..."
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <Button onClick={handleSendMessage}>Send</Button>
        </>
      )}
    </ChatContainer>
  );
};

export default Chatroom;