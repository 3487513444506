import React from 'react';
import styled from 'styled-components';

const ShareButton = styled.button`
  background-color: #ff007f;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e60073;
  }
`;

function SocialShare({ url, title }) {
  const handleShare = () => {
    const shareData = {
      title: title,
      text: `Check out this track: ${title}`,
      url: url,
    };

    if (navigator.share) {
      navigator.share(shareData).catch(console.error);
    } else {
      alert('Sharing not supported on this browser');
    }
  };

  return <ShareButton onClick={handleShare}>Share</ShareButton>;
}

export default SocialShare;
