import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  position: relative;
  z-index: 1;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  font-size: 1.1rem;
  color: #00ffd5;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
  border: 2px solid #00ffd5;
  border-radius: 25px;
  outline: none;
  text-shadow: 0 0 10px #00ffd5;
  box-shadow: 0 0 20px rgba(0, 255, 213, 0.7), inset 0 0 10px rgba(0, 0, 0, 0.7);
  transition: all 0.3s ease-in-out;

  &::placeholder {
    color: #ff007f;
    opacity: 0.7;
  }

  &:focus {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
    box-shadow: 0 0 30px rgba(0, 255, 213, 1), inset 0 0 15px rgba(0, 0, 0, 0.8);
    border-color: #ff007f;
  }
`;

function SearchBar({ searchTerm, onSearch }) {
  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder="Rechercher un morceau..."
        value={searchTerm}
        onChange={(e) => onSearch(e.target.value)}
      />
    </SearchContainer>
  );
}

export default SearchBar;
